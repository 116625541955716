import React from 'react';
import I18n from '../../models/i18n';
import { Link } from 'react-router-dom';

const Icon = props => {
  const { iconClass, iconUrl, iconAlt, iconText } = props.icon;

  const extLink = props.targetSection.indexOf('https://') === 0;
  return (
    <div className="icon-box">
      <img className={iconClass + ' img-fluid icon'} src={iconUrl} alt={iconAlt} />
      <h4 className="icon-title">{I18n.translate('icon', iconClass)}</h4>
      <p className="icon-paragraph">{I18n.translate('icon', iconText)}</p>
      {extLink && (
        <a href={props.targetSection} target="_blank" rel="noopener noreferrer">
          <h6 className="icon-learn">{I18n.translate('icon', 'learn_more')}</h6>
        </a>
      )}
      {!extLink && (
        <Link to={props.targetSection}>
          <h6 className="icon-learn">{I18n.translate('icon', 'learn_more')}</h6>
        </Link>
      )}
    </div>
  );
};

export default Icon;
