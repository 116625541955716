import React from 'react';
import Icon from '../Icon';

const IconSection = () => {
  return (
    <div className="bg-grey py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 text-center">
            <Icon
              icon={{
                iconClass: 'iconscience',
                iconUrl: '/assets/icon-science.png',
                iconAlt: 'icona scienza',
                iconText: 'science'
              }}
              targetSection="https://git.fluidware.it/fcub/"
            />
          </div>
          <div className="col-12 col-md-4 text-center">
            <Icon
              icon={{
                iconClass: 'icongraphs',
                iconUrl: '/assets/icon-graphs.png',
                iconAlt: 'icona dati rilevati',
                iconText: 'graphs'
              }}
              targetSection="/stations"
            />
          </div>
          <div className="col-12 col-md-4 text-center">
            <Icon
              icon={{
                iconClass: 'iconcode',
                iconUrl: '/assets/icon-code.png',
                iconAlt: 'icona open code',
                iconText: 'code'
              }}
              targetSection="https://git.fluidware.it/fcub/"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IconSection;
