import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../models/i18n';
import qs from 'qs';
import randomColor from 'randomcolor';
import Timeframe from '../CommonComponents/Timeframe';
import DatePicker from '../CommonComponents/DatePicker';
import { connect } from 'react-redux';
import Chart from './Chart';

const graphs = [
  { id: 'pm25', desc: 'PM 2.5' },
  { id: 'temp', desc: 'Temperature' }
];

const colors = [
  ...randomColor({ count: 5, hue: 'green', luminosity: 'dark' }),
  ...randomColor({ count: 5, hue: 'red', luminosity: 'dark' }),
  ...randomColor({ count: 5, hue: 'orange', luminosity: 'dark' }),
  ...randomColor({ count: 5, hue: 'purple', luminosity: 'dark' }),
  ...randomColor({ count: 5, hue: 'blue', luminosity: 'dark' }),
  ...randomColor({ count: 5, hue: 'pink', luminosity: 'dark' }),
  ...randomColor({ count: 5, hue: 'green', luminosity: 'dark' }),
  ...randomColor({ count: 5, hue: 'red', luminosity: 'dark' }),
  ...randomColor({ count: 5, hue: 'orange', luminosity: 'dark' }),
  ...randomColor({ count: 5, hue: 'purple', luminosity: 'dark' }),
  ...randomColor({ count: 5, hue: 'blue', luminosity: 'dark' }),
  ...randomColor({ count: 5, hue: 'pink', luminosity: 'dark' })
];

/*
  [
  '#339933',
  '#375e97',
  '#fb6542',
  '#ffbb00',
  '#7a5555',
  '#7f36e5',
  '#dabfff',
  '#37b680',
  '#b580ff',
  '#2d006b',
  '#644c72'
];
*/

class StatsPage extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    stations: false,
    data: null,
    width: undefined,
    resizing: false
  };

  refCol = React.createRef();

  render() {
    const { stations, data, width, resizing } = this.state;
    if (!stations) {
      return (
        <div className="top-page">
          <div>{I18n.translate('common', 'loading')}</div>
        </div>
      );
    }
    const queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const selectedStations = this.getStations(queries);
    const isStationSelected = function(code) {
      return selectedStations.indexOf(code) >= 0;
    };
    let showCharts = false;
    if (data) {
      showCharts = data.length > 0 && width && !resizing && queries.g;
    }
    const timeframe = this.getTimeframe(queries);
    return (
      <div className="stats-page">
        <div className="top-page bg-grey">
          <div className="container-fluid">
            <p>{I18n.translate('stats', 'select_stations')}</p>
            <div className="row">
              {stations.rows.map((station, id) => {
                return (
                  <div key={id} className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={'stationId' + id}
                        checked={isStationSelected(station.code)}
                        onChange={e => this.onChangeStation(e, station)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={'stationId' + id}
                        style={{ color: station.color || 'red' }}
                      >
                        {station.name}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label>{I18n.translate('stats', 'select_graph')}</label>
                  <select className="form-control" onChange={this.onChangeGraph} value={queries.g}>
                    <option />
                    {graphs.map((graph, i) => {
                      return (
                        <option key={i} value={graph.id}>
                          {graph.desc}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <Timeframe timeframe={timeframe} pushTimeframe={this.pushTimeframe} />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div>
                  {timeframe === 'custom' && (
                    <DatePicker
                      range={this.getRange()}
                      lang={this.props.lang}
                      onDateRangeChanged={this.onDateRangeChanged}
                      showCharts={showCharts}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {data && (
          <div className="my-5">
            <div className="container">
              {data.length === 0 && <div>{I18n.translate('error', 'no_data')}</div>}
              <div className="row">
                <div className="col-12 text-center" ref={this.refCol}>
                  {showCharts && (
                    <Chart
                      stations={stations.rows}
                      selectedStations={selectedStations}
                      data={data}
                      graph={queries.g}
                      width={width}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.fetchStations();
    window.addEventListener('resize', this.checkDimensions);
    this.checkDimensions(undefined, true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      this.loadData();
    }
    if (this.state.width === undefined) {
      this.checkDimensions(undefined, true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkDimensions);
  }

  checkDimensions = (e, force = false) => {
    if (this.refCol && this.refCol.current) {
      if (this.state.width !== this.refCol.current.offsetWidth) {
        if (force) {
          this.setState({ width: this.refCol.current.offsetWidth });
          return;
        }
        if (!this.state.resizing) {
          this.setState({ resizing: true });
        }
        if (this.timeoutResize) {
          clearTimeout(this.timeoutResize);
        }
        this.timeoutResize = setTimeout(() => {
          this.setState({ width: this.refCol.current.offsetWidth, resizing: false });
        }, 300);
      }
    }
  };

  loadData = () => {
    const queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const stations = this.getStations(queries);
    if (stations.length < 1) {
      this.setState({ data: null });
      return;
    }
    const stationsId = [];
    const allStations = Object.assign([], this.state.stations.rows);
    stations.forEach(station => {
      for (let i = 0; i < allStations.length; i++) {
        if (allStations[i].code === station) {
          stationsId.push(allStations[i].id);
          allStations.splice(i, 1);
          break;
        }
      }
    });
    const graph = queries.g;
    if (!graph) {
      this.setState({ data: null });
      return;
    }
    const timeframe = this.getTimeframe(queries);
    if (!timeframe) {
      this.setState({ data: null });
      return;
    }
    let range;
    if (timeframe === 'custom') {
      range = this.getRange(queries);
      if (!range) {
        this.setState({ data: null });
        return;
      }
    }
    const q = {
      stations: stationsId,
      graph,
      timeframe,
      range
    };
    fetch('/api/v1/stats' + qs.stringify(q, { addQueryPrefix: true, arrayFormat: 'comma' }))
      .then(res => {
        return res.json();
      })
      .then(data => this.setState({ data }));
  };

  fetchStations() {
    const queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { type } = queries;
    const url = `/api/v1/stations${type ? '?station_type=' + type : ''}`;
    fetch(url)
      .then(res => {
        return res.json();
      })
      .then(stations => {
        stations.rows.forEach((station, i) => {
          station.color = colors[i % colors.length];
        });
        this.setState({ stations });
        this.loadData();
      });
  }

  onChangeStation = (e, station) => {
    // get stations array from query.
    const stations = this.getStations();
    if (e.target.checked) {
      // add to array
      if (stations.indexOf(station.code) < 0) {
        stations.push(station.code);
      }
    } else {
      // remove from array
      const p = stations.indexOf(station.code);
      if (p >= 0) {
        stations.splice(p, 1);
      }
    }
    this.setStations(stations);
  };

  changeUrl = params => {
    console.log('this.props.location.pathname', this.props.location.pathname, params);
    this.props.history.push(this.props.location.pathname + qs.stringify(params, { addQueryPrefix: true }));
  };

  onChangeGraph = e => {
    const queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    queries.g = e.target.value;
    this.changeUrl(queries);
  };

  getStations = queries => {
    if (!queries) {
      queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    }
    const stations = queries.s || '';
    if (!stations) {
      return [];
    }
    return stations.split(',');
  };

  setStations = stations => {
    const queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    queries.s = stations.join(',');
    this.changeUrl(queries);
  };

  getTimeframe = queries => {
    if (!queries) {
      queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    }
    return queries.p || 'last_day';
  };

  getRange = queries => {
    if (!queries) {
      queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    }
    return queries.r;
  };

  pushTimeframe = e => {
    e.target.blur();
    const queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const timeframe = e.target.value;
    if (timeframe !== 'custom') {
      queries.r = '';
    }
    queries.p = timeframe;
    this.changeUrl(queries);
  };

  onDateRangeChanged = range => {
    if (range) {
      const queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
      queries.r = range;
      this.changeUrl(queries);
    }
  };
}

function mapStateToProps(state) {
  const { lang } = state;

  return {
    lang
  };
}

export default connect(mapStateToProps)(StatsPage);
